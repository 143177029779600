<template>
  <div class="custom-form">
    <div class="panel-body">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Request free property valuation</h3>
        </div>
        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Address1') }"
          >
            <label for="pva_Address1">First line of address</label>
            <input
              id="pva_Address1"
              name="pva_Address1"
              v-model.trim="fdata.pva_Address1"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_Address1") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Address2') }"
          >
            <label for="pva_Address2">Second line of address</label>
            <input
              id="pva_Address2"
              name="pva_Address2"
              v-model.trim="fdata.pva_Address2"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_Address2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Address3') }"
          >
            <label for="pva_Address3">Locality</label>
            <input
              id="pva_Address3"
              name="pva_Address3"
              v-model.trim="fdata.pva_Address3"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_Address3") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_City') }"
          >
            <label for="pva_City">City / Town</label>
            <input
              id="pva_City"
              name="pva_City"
              v-model.trim="fdata.pva_City"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_City") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Postcode') }"
          >
            <label for="pva_Postcode">Postcode</label>
            <input
              id="pva_Postcode"
              name="pva_Postcode"
              v-model.trim="fdata.pva_Postcode"
              v-validate.initial="{
                required: true,
                max: 8,
                valid_uk_postcode: { active: true }
              }"
              maxlength="8"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_Postcode") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Bedrooms') }"
          >
            <label for="pva_Bedrooms">Number of bedrooms</label>
            <select
              id="pva_Bedrooms"
              name="pva_Bedrooms"
              v-model="fdata.pva_Bedrooms"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="0">Studio</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <span class="help-block">{{ errors.first("pva_Bedrooms") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_PropertyType') }"
          >
            <label for="pva_PropertyType">Property type</label>
            <select
              id="pva_PropertyType"
              name="pva_PropertyType"
              v-model="fdata.pva_PropertyType"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">Detached</option>
              <option value="2">Semi-detached</option>
              <option value="3">Terraced</option>
              <option value="4">Flat</option>
              <option value="5">Bungalow</option>
              <option value="6">Land</option>
              <option value="7">Park Home</option>
              <option value="8">Student Halls</option>
            </select>
            <span class="help-block">{{
              errors.first("pva_PropertyType")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_ValuationType') }"
          >
            <label for="pva_ValuationType">Type of valuation</label>
            <select
              id="pva_ValuationType"
              name="pva_ValuationType"
              v-model="fdata.pva_ValuationType"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">Sales and Lettings Valuation</option>
              <option value="2">Sales Valuation</option>
              <option value="3">Lettings Valuation</option>
            </select>
            <span class="help-block">{{
              errors.first("pva_ValuationType")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_FullName') }"
          >
            <label for="pva_FullName">Full name</label>
            <input
              id="pva_FullName"
              name="pva_FullName"
              v-model.trim="fdata.pva_FullName"
              v-validate.initial="{
                required: true,
                max: 100,
                valid_name: { active: true }
              }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_FullName") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_EmailAddress') }"
          >
            <label for="pva_EmailAddress">Email address</label>
            <input
              id="pva_EmailAddress"
              name="pva_EmailAddress"
              v-model.trim="fdata.pva_EmailAddress"
              v-validate.initial="{ required: true, max: 100, email: true }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("pva_EmailAddress")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_Mobile') }"
          >
            <label for="pva_Mobile">Mobile number</label>
            <input
              id="pva_Mobile"
              name="pva_Mobile"
              v-model.trim="fdata.pva_Mobile"
              v-validate.initial="{
                required: true,
                max: 11,
                regex: [/^(07\d{9})$/, 'Must be valid UK mobile number']
              }"
              maxlength="11"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("pva_Mobile") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('pva_AdditionalInfo') }"
          >
            <label for="pva_AdditionalInfo">Any additional information</label>
            <textarea
              id="pva_AdditionalInfo"
              name="pva_AdditionalInfo"
              v-model.trim="fdata.pva_AdditionalInfo"
              v-validate="{ max: 500 }"
              maxlength="500"
              rows="4"
              class="form-control"
            ></textarea>
            <span class="help-block">{{
              errors.first("pva_AdditionalInfo")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="vue-recaptcha-wrapper">
        <vue-recaptcha
          ref="cap_recaptcha"
          id="cap_recaptcha"
          v-on:verify="verifyRecaptcha"
          sitekey="6LcZOc4ZAAAAAF9H52cuyXRl3hXEKPILrAE3DMID"
        ></vue-recaptcha>
      </div>

      <modal
        name="alert-dialog-valuation"
        :width="400"
        :height="'auto'"
        :clickToClose="false"
      >
        <div class="panel-primary">
          <div class="panel-heading modal-alert-heading">
            <span class="modal-alert-title">Information</span>
            <button
              @click="closeAlertDialog"
              type="button"
              class="close pull-right"
            >
              <span
                class="fa fa-times-circle modal-alert-close-button-icon"
              ></span>
            </button>
          </div>
          <div class="panel-body modal-alert-body">
            <div class="modal-alert-text-wrapper">
              <span class="fa fa-exclamation-circle modal-alert-icon"></span>
              <span class="modal-alert-text">
                {{ alertMessage }}
              </span>
            </div>
            <div class="modal-alert-footer">
              <button
                @click="closeAlertDialog"
                type="button"
                class="btn btn-default"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </modal>
      <div class="btn-submit-wrapper">
        <div v-if="showAnimation" class="app-animation">
          <span class="fa fa-spinner fa-spin fa-3x fa-fw"></span><br />
          <span>Form submission in progress</span>
        </div>
        <div class="app-submit-btn-wrapper">
          <button
            @click="saveFormData"
            :disabled="disableStatus_cap_recaptcha || showAnimation"
            type="submit"
            class="btn btn-common"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
export default {
  components: {
    VueRecaptcha
  },
  props: {
    baseUrl: { type: String }
  },
  data() {
    return {
      fdata: {
        pva_Address1: "",
        pva_Address2: "",
        pva_Address3: "",
        pva_Postcode: "",
        pva_City: "",
        pva_Bedrooms: null,
        pva_PropertyType: null,
        pva_ValuationType: null,
        pva_FullName: "",
        pva_EmailAddress: "",
        pva_Mobile: "",
        pva_AdditionalInfo: "",
        cap_recaptcha: ""
      },
      showAnimation: false,
      alertMessage: ""
    };
  },
  methods: {
    saveFormData: async function() {
      const valStatus = await this.$validator.validateAll();
      if (!valStatus || !this.fdata.cap_recaptcha) {
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-valuation");
        return;
      }
      this.showAnimation = true;

      let rData = null;
      let actionUrl = `${this.baseUrl}SavePropertyValuationRequest`;
      try {
        ({ data: rData } = await axios.post(actionUrl, this.fdata));
      } catch (error) {
        this.showAnimation = false;
        throw error;
      }

      this.showAnimation = false;
      if (rData == 0) {
        this.alertMessage =
          "Your request is submitted, We will get back to you very soon.";
        this.$modal.show("alert-dialog-valuation");
        this.resetFormData();
        this.$refs.cap_recaptcha.reset();
      } else {
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-valuation");
      }
    },
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog-valuation");
    },
    resetFormData: function() {
      Object.assign(this.fdata, {
        pva_Address1: "",
        pva_Address2: "",
        pva_Address3: "",
        pva_City: "",
        pva_Postcode: "",
        pva_Bedrooms: null,
        pva_PropertyType: null,
        pva_ValuationType: null,
        pva_FullName: "",
        pva_EmailAddress: "",
        pva_Mobile: "",
        pva_AdditionalInfo: ""
      });
    },
    verifyRecaptcha: function(res) {
      if (res) {
        this.fdata.cap_recaptcha = res;
      }
    }
  },
  computed: {
    disableStatus_cap_recaptcha: function() {
      if (this.fdata.cap_recaptcha) {
        return false;
      }
      return true;
    }
  }
};
</script>
